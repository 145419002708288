import React from "react"
import { Flex, Box, Text } from "rebass"
import { useThemeUI } from "theme-ui"
import _ from "lodash"

const Dots = ({
  dots = [],
  backgroundColor = "text",
  color = "white",
  px = [4, 9],
  py,
  pb,
}) => {
  const { theme } = useThemeUI()

  return (
    <Flex width="100%" px={px} my={0} py={py} pb={pb} justifyContent="center">
      {_.map(dots, (dot, index) => (
        <Flex
          key={index}
          ml={2}
          width={1 / 3}
          sx={{
            position: "relative",
            ":after":
              _.last(dots) !== dot
                ? {
                    content: "''",
                    position: "absolute",
                    top: "25%",
                    left: "55%",
                    height: 4,
                    backgroundImage:
                      "linear-gradient(to right,#B6BEC9 20%,rgba(255,255,255,0) 0)",
                    backgroundPosition: "center",
                    backgroundSize: "18px 3px",
                    backgroundRepeat: "repeat-x",
                    width: "96%",
                  }
                : "",
          }}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            sx={{
              backgroundColor: backgroundColor,
              borderRadius: "50%",
              zIndex: 1,
              height: 32,
              position: "relative",
              width: 32,
              border: `2px solid ${theme.colors[dot.borderColor]}`,
              ":after": {
                content: "''",
                position: "absolute",
                top: "38%",
                backgroundColor: dot.pointColor,
                borderRadius: "50%",
                left: "36%",
                height: 7,
                width: 7,
              },
            }}
          />

          <Text mt={2} fontWeight={300} color={color}>
            {dot.title}
          </Text>
        </Flex>
      ))}
    </Flex>
  )
}

export default Dots

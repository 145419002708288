import React from "react"
import { Flex, Text, Box, Image } from "rebass"
import { useThemeUI } from "theme-ui"
import PatternStandardImage from "../../assets/images/pattern.svg"
import PatternWatergreenImage from "../../assets/images/pattern-watergreen.svg"
import PatternPrimaryImage from "../../assets/images/pattern-primary.svg"

const Products = ({ backgroundColor, color, products }) => {
  const { theme } = useThemeUI()

  return (
    <Box px={[5, 6, 10]} py={[6, 7, 8]} backgroundColor={backgroundColor}>
      {products.map((product, i) => (
        <Flex
          key={i}
          justifyContent="center"
          flexDirection={["column-reverse", "column-reverse", "row"]}
          alignItems="center"
          py={[4]}
          px={[2, 2, 4]}
          sx={{
            "&:nth-child(even)": {
              flexDirection: [
                "column-reverse",
                "column-reverse",
                "row-reverse",
              ],
            },
            ...product.sx,
          }}
        >
          <Box
            width={[1, 1, 1 / 2]}
            py={[6, 6, 0]}
            mx={[0, 7]}
            sx={{
              position: "relative",
              textAlign: ["center", "center", i % 2 === 0 ? "left" : "right"],

              "&:before": {
                content: "''",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundImage:
                  product.patternColor === "standard"
                    ? `url(${PatternStandardImage})`
                    : product.patternColor === "watergreen"
                    ? `url(${PatternWatergreenImage})`
                    : `url(${PatternPrimaryImage})`,
                transform:
                  i % 2 === 0
                    ? "rotate(90deg) scale(1.4)"
                    : "rotate(-90deg) scale(1.4)",
                width: 450,
                height: ["150px"],
                opacity: 0.6,
                top: "250px",
                left: i % 2 === 0 ? "160px" : null,
                right: i % 2 === 0 ? null : "160px",
                display: product.hidePattern ? "none" : "block",
                position: "absolute",
              },
            }}
          >
            <Image
              sx={{
                position: "relative",
                zIndex: 1,
                width: [200, 300, "auto"],
                maxHeight: [350, 700],
              }}
              src={product.image}
            />
          </Box>

          <Box
            color={color}
            width={[1, 1, 1 / 2]}
            py={[0, 5]}
            mb={[3, 0]}
            maxWidth="500px"
            textAlign={["center", "center", "left"]}
          >
            <Text
              fontWeight={theme.fontWeights.heading}
              fontSize={[2, 3, 4]}
              as="h1"
            >
              {product.title}
            </Text>
            <Text
              mb={[3, 6]}
              fontWeight={theme.fontWeights.body}
              fontSize={[1, 2, 3]}
              lineHeight={["24px", "32px"]}
              as="h2"
            >
              {product.subtitle}
            </Text>
            <Text fontWeight={theme.fontWeights.light} fontSize={[0, 1]}>
              {product.description}
            </Text>
          </Box>
        </Flex>
      ))}
    </Box>
  )
}

export default Products
